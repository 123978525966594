import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://02ae484169377a7127b7611ed855a31b@o4506107102822400.ingest.sentry.io/4506185268920320",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});